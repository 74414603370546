import React from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'
import tw, { styled } from 'twin.macro'
import en from 'date-fns/locale/en-GB'
import nl from 'date-fns/locale/nl'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
//
import Layout from '~components/layout'
import PageLayout from '~storybook/layout'
import SEO from '~components/seo'
import { ParagraphComponents } from '~utils/components'
import ImageProxy from '~storybook/image'
import SocialShare from '~storybook/social-share'
import NavLink from '~utils/navlink'
import { Heading, Label, SuperParagraph, Tag } from '~storybook/blocks/typography/index'
import Icons from '~storybook/icons/index'
import { CardSet } from '~storybook/cards/index'
import { formatTZ } from '~utils/dates'

const localesObj = {
  en,
  nl,
  fr,
  de
}

const ContentWrapper = styled.div`
  p {
    ${tw`text-lg`}
  }
`

const NewsNav = styled(NavLink)`
  ${tw`text-c-news-template-text no-underline hover:underline flex flex-col md:flex-row items-center`}

  * {
    ${tw`font-medium text-xl text-c-news-template-text no-underline hover:underline`}
  }

  svg {
    width: 32px;
    height: 32px;
    margin-top: 8px;
  }

  &.prev {
    svg {
      margin-right: 8px;
    }
  }

  &.next {
    ${tw`flex-col-reverse md:flex-row`}
    svg {
      margin-left: 8px;
    }
  }
`

const News = ({ data, pageContext, location }) => {
  const { node, group } = data.drupal
  const { nid, language, menus, allNews, metatags } = pageContext || {}
  const {
    fieldNewsDate,
    fieldParagraphs,
    fieldSidebar,
    fieldEntityImage,
    fieldNewsSource,
    fieldRelatedNews,
    fieldTypeLabel
  } = node || {}
  const langCode = language?.toLowerCase()
  const langMenus = menus[langCode] || {}
  const {
    fieldSidebar: fieldSiteSidebar,
    fieldSocialShareButtons,
    fieldAnnouncement,
    fieldGdprTitle,
    fieldGdprDescription,
    fieldGdprCookies = [],
    fieldMetaSiteImage,
    fieldCss,
    fieldDisableLangSwitcher,
    fieldMiniGdprAnnouncement,
    id
  } = group || {}

  const sidebarParagraphs = (fieldSidebar?.length && fieldSidebar) || fieldSiteSidebar || null
  const sidebar = sidebarParagraphs ? <ParagraphComponents paragraphs={sidebarParagraphs} language={langCode} /> : null

  const newsDate = new Date(fieldNewsDate?.value)

  const currIdx = allNews.findIndex((item) => item.nid.toString() === nid)

  const previousSlug = allNews?.[currIdx - 1]?.[langCode]?.slug
  const nextSlug = allNews?.[currIdx + 1]?.[langCode]?.slug

  const sources = fieldNewsSource?.map((source) => source?.entity) || []

  const intl = useIntl()
  const dateStr = newsDate && `${formatTZ(newsDate, 'd LLLL yyyy | HH:mm', { locale: localesObj?.[intl?.locale] })}`

  const availableNews = allNews?.map((item) => item.nid) || []

  const relatedNews = fieldRelatedNews
    ?.map((item) => item?.entity)
    ?.filter((entity) => availableNews.includes(entity.id))

  return (
    <Layout
      language={langCode}
      menus={langMenus}
      announcement={fieldAnnouncement}
      fieldCss={ fieldCss }
      disableLangSwitcher={fieldDisableLangSwitcher}
      cookiesInfo={{ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }}
      miniGdprAnnouncement={fieldMiniGdprAnnouncement}
    >
      <SEO
        key={`news-${nid}-${langCode}`}
        title={node.title}
        metatags={metatags}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
        lang={langCode}
        nid={nid}
        gid={id}
        langSlugs={pageContext.langSlugs}
      />
      <PageLayout sidebar={sidebar}>
        <div className="flex flex-row">
          <div>
            <Tag css={tw`text-c-news-template-text`}>{fieldTypeLabel || intl.formatMessage({ id: 'news.news' })}</Tag>
          </div>
          {dateStr && (
            <div className="ml-2">
              <Label css={tw`text-c-news-template-label`}>{dateStr}</Label>
            </div>
          )}
        </div>

        <h1 className="my-4">{node?.title}</h1>

        <div className="my-4 py-4">
          <SocialShare enabled={fieldSocialShareButtons} url={location?.href} />
        </div>

        {fieldEntityImage?.entity?.thumbnail?.url ? (
          <section className="bg-white-500 w-full mb-8 relative">
            <ImageProxy
              type="fluid"
              url={fieldEntityImage?.entity?.thumbnail?.url}
              params={{ mw: 1584, mh: 892, g: 'sm', r: 'fill' }}
            />
          </section>
        ) : null}

        <ContentWrapper>
          <ParagraphComponents paragraphs={fieldParagraphs} language={langCode} />
        </ContentWrapper>

        {sources?.map((source) => (
          <p key={`source-${source.entityId}`} css={tw`text-c-news-template-text font-medium text-sm`}>
            ({source.entityLabel})
          </p>
        ))}

        <section css={tw`my-8 py-8 border-c-news-template-border flex justify-between`}>
          {previousSlug ? (
            <div className="justify-left">
              <NewsNav className="prev" to={previousSlug}>
                <Icons.ArrowLeft />
                <SuperParagraph>{intl.formatMessage({ id: 'news.prevArticle' })}</SuperParagraph>
              </NewsNav>
            </div>
          ) : null}

          {nextSlug ? (
            <div className="justify-right">
              <NewsNav className="next" to={nextSlug}>
                <SuperParagraph>{intl.formatMessage({ id: 'news.nextArticle' })}</SuperParagraph>
                <Icons.ArrowRight />
              </NewsNav>
            </div>
          ) : null}
        </section>

        <hr css={tw`border-c-news-template-divide mb-20`} />
        {Array.isArray(relatedNews) && relatedNews?.length > 0 ? (
          <section className="bg-white-500 w-full mb-8 relative">
            <Heading className="font-medium mt-10 mb-10" level="h3">
              {intl.formatMessage({ id: 'news.relatedArticles' })}
            </Heading>
            <CardSet
              cards={relatedNews?.map((related) => {
                return {
                  type: 'card_newsfeed',
                  id: related?.id,
                  title: related?.title,
                  category: related?.fieldTypeLabel || intl.formatMessage({ id: 'news.news' }),
                  date: new Date(related?.fieldNewsDate?.value),
                  image: {
                    url: related?.fieldEntityImage?.entity?.thumbnail?.url
                  },
                  cta: {
                    label: intl.formatMessage({ id: 'news.readMore' }),
                    url: related?.path?.alias || `/${langCode}/node/${related?.id}`
                  }
                }
              })}
            />
          </section>
        ) : null}
      </PageLayout>
    </Layout>
  )
}

export default News

export const newsQuery = graphql`
  query($gid: String!, $nid: String!, $language: Drupal_LanguageId!) {
    drupal {
      node: nodeById(id: $nid, language: $language) {
        title
        path {
          alias
        }
        entityId
        langcode {
          value
        }
        ... on Drupal_NodeNews {
          fieldNewsDate {
            value
          }
          fieldTypeLabel
          fieldNewsSource {
            entity {
              entityId
              entityLabel
            }
          }
          fieldEntityImage {
            entity {
              ...FragmentImage
            }
          }
          fieldRelatedNews {
            entity {
              ... on Drupal_NodeNews {
                id: nid
                title
                fieldNewsDate {
                  value
                }
                fieldTypeLabel
                path {
                  alias
                }
                fieldEntityImage {
                  entity {
                    ...FragmentImage
                  }
                }
              }
            }
          }
          fieldParagraphs {
            ...FragmentNewsFieldParagraphs
          }
          fieldSidebar {
            ...FragmentNewsFieldSidebarParagraphs
          }
        }
      }
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
